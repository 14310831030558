<template>
    <div class="user-profile fade-in">
        <div class="model-header" style="font-size:24px;color:var(--gold);">פרופיל מנהל מערכת</div>
        <div class="input-label">
            <p>שם מלא</p>
        </div>
        <div class="input-field">
            <input type="text" name="name" id="name" class="txt-input" v-model="name" @change="checkName">
        </div>
        <div class="input-label">
            <p>מספר פלאפון</p>
        </div>
        <div class="input-field">
            <input type="text" name="" id="" class="txt-input" :placeholder="user.phone.replace('+972', '0')" disabled>
        </div>
        <div class="input-label">
            <p>עיר מגורים</p>
        </div>
        <div class="input-field">
            <input list="select-city" name="city" id="city" class="txt-input" placeholder="בחר מרשימה" autocomplete="off" v-model="selected_city" @change="get_streets">
            <datalist id="select-city">
                <template v-for="city in cities" :key="city">
                    <option :value="city.trim()">{{city}}</option>
                </template>
            </datalist>
        </div>
        <div class="input-label">
            <p>רחוב</p>
        </div>
        <div class="input-field">
            <input list="select-street" name="street" id="street" class="txt-input" placeholder="בחר מרשימה" autocomplete="off" v-model="selected_street">
            <datalist id="select-street">
                <template v-for="street in streets" :key="street">
                    <option :value="street">{{street}}</option>
                </template>
            </datalist>
        </div>
        <div class="input-label">
            <p>מספר</p>
        </div>
        <div class="input-field">
            <input name="street" type="number" id="street_num" class="txt-input" placeholder="מס דירה" autocomplete="off" v-model="street_num">
        </div>
        <button class="success-btn" style="width:100%; max-width:400px; margin-top:25px;" @click="continue_next">
            <span>המשך</span>
        </button>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { computed,onMounted,} from '@vue/runtime-core';
import store from '../../../../store';
import {get_citys_from_api, get_streets_by_city_api} from '../../../../Scripts/AdressAPI';
import { slide_pop_error } from '../../../../Scripts/Msgs';
import router from '../../../../router';
import { projectAuth } from '../../../../Firebase/config';
export default {
setup(){
    const user = ref(computed(() => {
        return store.state.user
    }))
    const selected_city = ref(null);
    const selected_street = ref(null);
    const name = ref(null);
    const street_num = ref(null);

    const cities = ref([]);
    const streets_api = ref(null);

    const streets = ref(computed(() => {
        if(selected_city.value){
            return streets_api.value
        }
        return []
    }))

    const get_cities = async () =>{
        cities.value = await get_citys_from_api();
    }

    const toggle_input = (name) => {
        let el = document.getElementById(name);
        el.focus();
    }

    const get_streets = async () => {
        if(selected_city.value){
            selected_street.value = null;
            streets_api.value = await get_streets_by_city_api(selected_city.value.trim())
        }
    }

    const checkName = () => {
        if(!name.value || name.value.length < 3 || name.value.split < 2){
            slide_pop_error("אנא מלא שם מלא ותקני", 3000)
            toggle_input('name');
            return false
        }
        return true
    }

    const checkStreet = () => {
        if(!selected_street.value ){
            slide_pop_error("אנא בחר רחוב מגורים", 3000)
            toggle_input('street');
            return false
        }
        return true
    }

    const checkCity = () => {
        if(!selected_city.value ){
            slide_pop_error("אנא בחר עיר מגורים", 3000)
            toggle_input('city');
            return false
        }
        return true
    }

    const checkStreetNum = () => {
        if(!street_num.value ){
            slide_pop_error("אנא מלא מספר רחוב מגורים", 3000)
            toggle_input('street_num');
            return false
        }
        return true
    }

    const continue_next = () => {
        if(!checkName()){
            return
        }
        if(!checkCity()){
            return
        }
        if(!checkStreet()){
            return
        }
        if(!checkStreetNum()){
            return
        }
        if(!store.state.temp_obj || store.state.temp_obj.model != 'neworg'){
            store.state.temp_obj = {
                model: 'neworg',
                name: name.value,
                selected_city: selected_city.value,
                selected_street: selected_street.value,
                street_num: street_num.value,
                user_uid: projectAuth.currentUser.uid,
                phone: projectAuth.currentUser.phoneNumber,

            }
        }else{
            store.state.temp_obj.name = name.value;
            store.state.temp_obj.selected_city = selected_city.value;
            store.state.temp_obj.selected_street = selected_street.value;
            store.state.temp_obj.street_num = street_num.value;
            store.state.temp_obj.user_uid = projectAuth.currentUser.uid;
            store.state.temp_obj.phone = projectAuth.currentUser.phoneNumber;
        }

        router.push('setupneworg2')
    }

    onMounted(() => {
        get_cities();
        if(store.state.temp_obj && store.state.temp_obj.model == 'neworg'){
            name.value = store.state.temp_obj.name,
            selected_city.value = store.state.temp_obj.selected_city,
            selected_street.value = store.state.temp_obj.selected_street,
            street_num.value = store.state.temp_obj.street_num
        }
    })
    return{
        user, cities, streets, selected_city, get_streets, selected_street,
        continue_next, name, street_num, checkName
    }
}
}
</script>

<style scoped>
.user-profile{
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 80px 5px 0 5px;
    flex-shrink: 0;
    height: auto;
}
datalist option {
    font-size: 36px;
    padding: 0.3em 1em;
    background-color: red;
    cursor: pointer;
}

</style>