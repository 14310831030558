const get_streets_by_city_api = async (city) =>{
    const response = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=9ad3862c-8391-4b2f-84a4-2d4c68625f4b&limit=1000&q=${city}`)
    let data = await response.json()
    const streets = data.result.records.map(street=>street.שם_רחוב)
    return [...new Set(streets)]
}

const get_citys_from_api = async () =>{
    const response = await fetch('https://data.gov.il/api/3/action/datastore_search?resource_id=d4901968-dad3-4845-a9b0-a57d027f11ab&limit=2000')
    let data = await response.json()
    const cities = data.result.records.map(area=>area.שם_ישוב).filter(city=>{
        if(!city.includes('שבט') && !city.includes('לא רשום') ){
            return city
        }
    })
    return cities
}

export{get_citys_from_api, get_streets_by_city_api}